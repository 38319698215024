import React from 'react'
import { RiProfileLine } from 'react-icons/ri'
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard'
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'

function LocationScreen() {
    return (
        <NavCardList numOfCards={'six'}>
            <NavCard cardName={'Divisions'} navCardLink='/divisions' ><RiProfileLine /></NavCard>
            
          
        </NavCardList>
    )
}

export default LocationScreen