import { useState } from "react"
import axios from "axios"


import { AREAS_API } from "../../../../Utilities/APIs"
import Form from "../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { useEffect } from "react"


function UpdateArea({

     targetID,employee, setShowUpdateForm, setShowModal, triggerFetch

}) {

    const [name, setName] = useState('')
    const [bngName, setBngName] = useState('')

    
    useEffect(() => {
        
        async function fetchAndSetSingleArea() {
            const { data } = await axios.get(AREAS_API + targetID)
            setName(data.name)
            setBngName(data.bngName)
           
        }
        fetchAndSetSingleArea()
    }, [targetID])

    async function handleSubmit(e) {

        e.preventDefault()

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }
       const areas = { name, bngName}

        const response = await axios.patch(AREAS_API + targetID , areas)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);

            triggerFetch()
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label={`name`}
                value={name}
                placeholder={`Enter Name`}
                setState={setName}
            />
            <ShortTextInput
                label={`Bangla Name`}
                value={bngName}
                placeholder={`Enter Bangla Name`}
                setState={setBngName}
            />
            
            
           
            

            {/* <ImageInput fieldId='1' state={image} setState={setImage} allowCreateImage>Upload Card Image</ImageInput> */}

            <FormSubmitButton text='Update Area' />
        </Form>
    )
}

export default UpdateArea