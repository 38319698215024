import { useState, useEffect } from "react"
import axios from "axios"

import { DISTRICTS_API} from "../../../../Utilities/APIs"


function ViewDistrict({

    //common props
    targetID, employee

}) {

    const [district, setDistrict] = useState(null)
    console.log(district)
    useEffect(() => {

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }

        async function fetchAndSetDistrict() {

            const { data } = await axios.get(DISTRICTS_API  + targetID)
            setDistrict(data);
        }
        fetchAndSetDistrict()
    }, [targetID])

    return (

        <div className="crud_view_content">

            {district &&
                <>
                    <h1>Name</h1>
                    <p>{district.name}</p>
                    <h1>Bangla Name</h1>
                    <p>{district.bngName}</p>
                    <h1>Path Drawn</h1>
                    <p>{district.pathDrawn}</p>
                    <h1>FillColor</h1>
                    <p>{district.fillColor}</p>
                    <h1>Population</h1>
                    <p>{district.population}</p>
                    <h1>LandArea</h1>
                    <p>{district.landArea}</p>
                    <h1>Time Same As Dhaka?</h1>
                    <p>{district.timeSameAsDhaka}</p>
                    <h1>Time Difference</h1>
                    <p>{district.timeDifference}</p>
                    
                    
                  
                </>
            }
        </div>
    )
}

export default ViewDistrict