import { backendUrl, spacesBucketUrl } from "../Constants"

export const BASE_URL = backendUrl
export const IMAGE_URL = spacesBucketUrl
export const API_URL = BASE_URL + 'api/'

export const EMPLOYEES_API = API_URL + 'employees/'
export const DESIGNATIONS_API = API_URL + 'designations/'
export const EMPLOYEE_DESIGNATIONS_API = API_URL + 'employeeDesignations/'

export const APP_SETTINGS_API = API_URL + 'appSettings/'


export const DIVISIONS_API = API_URL + 'locations/divisions/'
export const DISTRICTS_API = API_URL + 'locations/districts/'
export const DISTRICTS_FROM_DIVISION_API = API_URL + 'locations/districts/getDistrictsFromDivision/'
export const AREAS_API = API_URL + 'locations/areas/'
export const AREAS_FROM_DISTRIC_API = API_URL + 'locations/areas/getAreasFromDistrict/'
export const PLACE_TO_VISITS_API = API_URL + 'locations/placeToVisites/'
export const PLACE_TO_VISITS_FROM_DISTRICT_API = API_URL + 'locations/placeToVisites/getPlaceToVisitesFromDistrict/'
export const FAMOUS_FOODS_API = API_URL + 'locations/famousFoods/'
export const FAMOUS_FOODS_FROM_DISTRICT_API = API_URL + 'locations/famousFoods/getFamousFoodsFromDistrict/'



