import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import axios from "axios"

// import { CLIENTS_API, CONTENT_GENRES_API } from "../../../Utilities/APIs"

import { DIVISIONS_API } from "../../../../Utilities/APIs"
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton"
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable"
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader"
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth"
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow"
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell"
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton"
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton"
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton"
import Modal from "../../../Partials/Elements/Modal/Modal"
import CRUDDeleteBoilerPlate from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDeleteBoilerPlate/CRUDDeleteBoilerPlate"
import ViewDivision from "./ViewDivision"
import CreateDivision from "./CreateDivision"
import UpdateDivision from "./UpdateDivision"
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton"
import { useNavigate } from "react-router-dom"
import { FiImage } from "react-icons/fi"



function Divisions() {
    const navigate = useNavigate()
    const [divisions, setDivisions] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [targetID, setTargetID] = useState(null)
    const [showViewSection, setShowViewSection] = useState(false)
    const [showCreateForm, setShowCreateForm] = useState(false)
    const [showUpdateForm, setShowUpdateForm] = useState(false)
    const [showDeleteSection, setShowDeleteSection] = useState(false)
    const [toggleFetch, setToggleFetch] = useState(false)

    const { employee } = useSelector(state => state.auth)



    useEffect(() => {
        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }

        async function fetchAndSetDivisions() {
            const { data } = await axios.get(DIVISIONS_API)
            setDivisions(data)
        }
        fetchAndSetDivisions()


    }, [toggleFetch])

    function handleClose() {
        setShowModal(false);
        setShowCreateForm(false);
        setShowViewSection(false);
        setShowUpdateForm(false);
        setShowDeleteSection(false);
    }

    function triggerFetch() {
        setToggleFetch(prevState => !prevState)
    }


    return (
        <>
            <div className='crud_holder'>
                <div className="body_header">
                    <h1 className="heading">{`Divisions (${divisions?.length} in total)`}</h1>
                    {/* <CreateButton
                        screenTopicSingular='Division'
                        setShowModal={setShowModal}
                        setShowCreateForm={setShowCreateForm}
                    /> */}
                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th='Name' />
                        <CRUDth th='Bangla Name' />
                        <CRUDth th='Actions' />
                    </CRUDTableHeader>
                    <tbody>
                        {divisions?.map(division => (
                            <CRUDTableRow key={division._id}>
                                <ShortTextCell text={division.name} />
                                <ShortTextCell text={division.bngName} />


                                <td className="action_button_cell">
                                    <ViewButton
                                        setShowModal={setShowModal}
                                        setShowViewSection={setShowViewSection}
                                        targetID={division._id}
                                        setTargetID={setTargetID}
                                    />
                                    {/* <EditButton
                                        setShowModal={setShowModal}
                                        setShowUpdateForm={setShowUpdateForm}
                                        targetID={division._id}
                                        setTargetID={setTargetID}
                                    />
                                    <DeleteButton
                                        setShowModal={setShowModal}
                                        setShowDeleteSection={setShowDeleteSection}
                                        targetID={division._id}
                                        setTargetID={setTargetID}
                                    /> */}
                                    <CRUDButton
                                        handleClick={() => navigate('/districts/' + division._id)}
                                    >
                                        <FiImage />
                                    </CRUDButton>

                                </td>
                            </CRUDTableRow>
                        ))}
                    </tbody>
                </CRUDTable>
            </div>
            {showModal &&
                <Modal
                    handleClose={handleClose}
                    modalHeading={
                        (showCreateForm && `Create Division`) ||
                        (showUpdateForm && `Update Division`) ||
                        (showDeleteSection && `Delete Division`)
                    }
                >
                    {
                        showCreateForm &&
                        <CreateDivision
                            employee={employee}
                            setShowCreateForm={setShowCreateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    }
                    {
                        showUpdateForm &&
                        <UpdateDivision
                            employee={employee}
                            targetID={targetID}
                            setShowUpdateForm={setShowUpdateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    }
                    {
                        showDeleteSection &&
                        <CRUDDeleteBoilerPlate
                            api={DIVISIONS_API}
                            targetID={targetID}
                            employee={employee}
                            setShowModal={setShowModal}
                            setShowDeleteSection={setShowDeleteSection}
                            hasName
                            triggerFetch={triggerFetch}
                        />
                    }
                </Modal>
            }

            {showModal && showViewSection &&
                <Modal
                    view
                    handleClose={handleClose}
                    modalHeading={
                        (showViewSection && `View Division`)
                    }
                >
                    <ViewDivision
                        targetID={targetID}
                        employee={employee}
                    />
                </Modal>
            }
        </>
    )
}

export default Divisions