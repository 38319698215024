
import INCRUD from "../../Partials/Layouts/CRUDs/CRUDLayouts/INCRUD/INCRUD";

function AppSettings() {
  return (
    <INCRUD 
        // api={APP_SETTINGS_API}
        screenTopicSingular='App Setting'
        screenTopicPlural='App Settings'

        // crud props
        // allowCreate
        allowUpdate
    />
  )
}

export default AppSettings