import SmallSideBarItem from './SmallSideBarItem/SmallSideBarItem';

import { RiHome2Line, RiMapPinLine, RiSettings2Line } from 'react-icons/ri';

import './SideBar.css';

function SideBar() {

    return (
        <>
            <section className="small_sidebar">
                <div className='container'>

                    <SmallSideBarItem
                        link='/'
                        dataTip='Dashboard'
                        dataFor='dashboard'
                    >
                        <RiHome2Line />
                    </SmallSideBarItem>

                    <SmallSideBarItem
                        link='/locationScreen'
                        dataTip='Location'
                        dataFor='Location'
                    >
                        <RiMapPinLine />
                    </SmallSideBarItem>
                  

                    <SmallSideBarItem
                        link='/settingsScreen'
                        dataTip='Settings'
                        dataFor='settings'
                    >
                        <RiSettings2Line />
                    </SmallSideBarItem>
                </div>
            </section>
        </>
    )
}

export default SideBar
