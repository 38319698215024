import { useState } from "react"
import axios from "axios"


import { DISTRICTS_API} from "../../../../Utilities/APIs"
import Form from "../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import LongTextInput from "../../../Partials/Layouts/Forms/FormInputs/LongTextInput/LongTextInput"
import { useEffect } from "react"
import Switch from "react-switch";
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput"


function UpdateDistrict({

    division, targetID, employee, setShowUpdateForm, setShowModal, triggerFetch

}) {

    const [name, setName] = useState('')
    const [bngName, setBngName] = useState('')
    const [pathDrawn, setPathDrawn] = useState('')
    const [fillColor, setFillColor] = useState('')
    const [population, setPopulation] = useState('')
    const [landArea, setLandArea] = useState('')
    const [timeSameAsDhaka, setTimeSameAsDhaka] = useState(false)
    const [timeDifference, setTimeDifference] = useState('')


    const handleChange = nextChecked => {
        setTimeSameAsDhaka(nextChecked);
    };

 
    useEffect(() => {
        
        async function fetchAndSetSingleDistrict() {
            const { data } = await axios.get(DISTRICTS_API + targetID)
            setName(data.name)
            setBngName(data.bngName)
            setPathDrawn(data.pathDrawn)
            setFillColor(data.fillColor)
            setPopulation(data.population)
            setLandArea(data.landArea)
            setTimeSameAsDhaka(data.timeSameAsDhaka)
            setTimeDifference(data.timeDifference)
        }
        fetchAndSetSingleDistrict()
    }, [targetID])
 

    async function handleSubmit(e) {

        e.preventDefault()

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }
       const districts = { name, bngName, pathDrawn, division, fillColor ,population ,landArea, timeSameAsDhaka, timeDifference}

        const response = await axios.patch(DISTRICTS_API + targetID , districts)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);

            triggerFetch()
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label={`name`}
                value={name}
                placeholder={`Enter Name`}
                setState={setName}
            />
            <ShortTextInput
                label={`Bangla Name`}
                value={bngName}
                placeholder={`Enter Bangla`}
                setState={setBngName}
            />
            <LongTextInput
                label={`Path Drawn`}
                value={pathDrawn}
                placeholder={`Enter Path Drawn`}
                setState={setPathDrawn}
            />
          
            <ShortTextInput
                label={`Fill Color`}
                value={fillColor}
                placeholder={`Enter Fill Color`}
                setState={setFillColor}
            />
            <ShortTextInput
                label={`Population`}
                value={population}
                placeholder={`Enter Population`}
                setState={setPopulation}
            />
            <ShortTextInput
                label={`Land Area`}
                value={landArea}
                placeholder={`Enter Land Area`}
                setState={setLandArea}
            />
             <div>
                <label className="input_field_label">
                    Time Same As Dhaka?</label>
                <Switch
                    onChange={handleChange}
                    checked={timeSameAsDhaka}
                    className="react-switch"
                />

            </div>
            <NumberInput
                label={`Time Difference`}
                value={timeDifference}
                placeholder={`Enter Time Difference`}
                setState={setTimeDifference}
            />
            

            <FormSubmitButton text='Update District' />
        </Form>
    )
}

export default UpdateDistrict