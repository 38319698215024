import { useState } from "react"
import axios from "axios"


import { FAMOUS_FOODS_API } from "../../../../Utilities/APIs"
import Form from "../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"


function CreateFamousFood({

    district, employee, setShowCreateForm, setShowModal, triggerFetch,

}) {

    const [name, setName] = useState('')





    async function handleSubmit(e) {

        e.preventDefault()

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }
        const fomousFoods = { district: district?._id, name, }

        const response = await axios.post(FAMOUS_FOODS_API, fomousFoods)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);

            triggerFetch()
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <h1 className="input_field_label">District</h1>
            <p className="short_title">{district?.name}</p>
            <ShortTextInput
                label={`name`}
                value={name}
                placeholder={`Enter Name`}
                setState={setName}
            />

            {/* <ImageInput fieldId='1' state={image} setState={setImage} allowCreateImage>Upload Card Image</ImageInput> */}

            <FormSubmitButton text='Create Famous Food' />
        </Form>
    )
}

export default CreateFamousFood