import { useState, useEffect } from "react"
import axios from "axios"

import { PLACE_TO_VISITS_API } from "../../../../Utilities/APIs"


function ViewPlaceToVisit({

    //common props
    targetID, employee

}) {

    const [placeToVisit, setPlaceToVisit] = useState(null)
    console.log(placeToVisit)
    useEffect(() => {

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }

        async function fetchAndSetPlaceToVisit() {

            const { data } = await axios.get(PLACE_TO_VISITS_API + targetID)
            setPlaceToVisit(data);
        }
        fetchAndSetPlaceToVisit()
    }, [targetID])

    return (

        <div className="crud_view_content">

            {placeToVisit &&
                <>
                    <h1>Name</h1>
                    <p>{placeToVisit.name}</p>
                    <h1>Map Link</h1>
                    <p>{placeToVisit.mapLink}</p>
                    <h1>Wiki Link</h1>
                    <p>{placeToVisit.wikiLink}</p>

                </>
            }
        </div>
    )
}

export default ViewPlaceToVisit    