import { useState, useEffect } from "react"
import axios from "axios"

import { DIVISIONS_API } from "../../../../Utilities/APIs"


function ViewDivision({

    //common props
    targetID, employee

}) {

    const [division, setDivision] = useState(null)
    console.log(division)
    useEffect(() => {

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }

        async function fetchAndSetDivision() {

            const { data } = await axios.get(DIVISIONS_API  + targetID)
            setDivision(data);
        }
        fetchAndSetDivision()
    }, [targetID])

    return (

        <div className="crud_view_content">

            {division &&
                <>
                    <h1>Name</h1>
                    <p>{division.name}</p>
                    <h1>Bangla Name</h1>
                    <p>{division.bngName}</p>
                    <h1>Path Drawn</h1>
                    <p>{division.pathDrawn}</p>
                    <h1>FillColor</h1>
                    <p>{division.fillColor}</p>
                    
                    
                  
                </>
            }
        </div>
    )
}

export default ViewDivision