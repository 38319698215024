import { useState } from "react"
import axios from "axios"


import { DISTRICTS_API } from "../../../../Utilities/APIs"
import Form from "../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import LongTextInput from "../../../Partials/Layouts/Forms/FormInputs/LongTextInput/LongTextInput"
import Switch from "react-switch";
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput"

function CreateDistrict({

    division, employee, setShowCreateForm, setShowModal, triggerFetch,

}) {

    const [name, setName] = useState('')
    const [bngName, setBngName] = useState('')
    const [pathDrawn, setPathDrawn] = useState('')
    const [fillColor, setFillColor] = useState('')
    const [population, setPopulation] = useState('')
    const [landArea, setLandArea] = useState('')
    const [timeSameAsDhaka, setTimeSameAsDhaka] = useState(false)
    const [timeDifference, setTimeDifference] = useState('')


    const handleChange = nextChecked => {
        setTimeSameAsDhaka(nextChecked);
    };

    async function handleSubmit(e) {

        e.preventDefault()

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }
        const districts = { name, bngName, pathDrawn, division: division?._id, fillColor, population, landArea, timeSameAsDhaka, timeDifference }

        const response = await axios.post(DISTRICTS_API, districts)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);

            triggerFetch()
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <h1 className="input_field_label">Division</h1>
            <p className="short_title">{division?.name}</p>
            <ShortTextInput
                label={`name`}
                value={name}
                placeholder={`Enter Name`}
                setState={setName}
            />
            <ShortTextInput
                label={`Bangla Name`}
                value={bngName}
                placeholder={`Enter Bangla Name`}
                setState={setBngName}
            />
            <LongTextInput
                label={`Path Drawn`}
                value={pathDrawn}
                placeholder={`Enter Path Drawn`}
                setState={setPathDrawn}
            />

            <ShortTextInput
                label={`Fill Color`}
                value={fillColor}
                placeholder={`Enter Fill Color`}
                setState={setFillColor}
            />
            <ShortTextInput
                label={`Population`}
                value={population}
                placeholder={`Enter Population`}
                setState={setPopulation}
            />
            <ShortTextInput
                label={`LandArea`}
                value={landArea}
                placeholder={`Enter LandArea`}
                setState={setLandArea}
            />
            <div>
                <label className="input_field_label">
                    Time Same As Dhaka?</label>
                <Switch
                    onChange={handleChange}
                    checked={timeSameAsDhaka}
                    className="react-switch"
                />

            </div>
            <NumberInput
                label={`Time Difference`}
                value={timeDifference}
                placeholder={`Enter Time Difference`}
                setState={setTimeDifference}
            />


            <FormSubmitButton text='Create District' />
        </Form>
    )
}

export default CreateDistrict