import React from 'react'
import { RiProfileLine } from 'react-icons/ri'
import { useParams } from 'react-router-dom';
import NavCard from '../../../Partials/Layouts/NavCardList/NavCard/NavCard'
import NavCardList from '../../../Partials/Layouts/NavCardList/NavCardList'

function DistrictDashboard() {
    const { id } = useParams();
    return (
        <NavCardList numOfCards={'six'}>
        
            <NavCard cardName={'Place To Visits'} navCardLink={'/placeToVisits/' + id} ><RiProfileLine /></NavCard>
            <NavCard cardName={'Famous Foods'} navCardLink={'/famousFoods/' + id}><RiProfileLine /></NavCard>

        </NavCardList>
    )
}

export default DistrictDashboard