import React from 'react'
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'

function HomeScreen() {
  return (
    <NavCardList numOfCards={'six'}>
      {/* <NavCard cardName={'Sliders'} navCardLink='/sliders' ><RiProfileLine /></NavCard> */}

     
    </NavCardList>
  )
}

export default HomeScreen