// import DeleteClient from '../Clients/DeleteClient/DeleteClient';
// import { useState } from 'react';
// import Form from '../../Partials/Layouts/Forms/Form';
// import SelectInput from '../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput';
// import SelectOption from '../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOptions/SelectOption';
// import TextInput from '../../Partials/Layouts/Forms/FormInputs/TextInput/TextInput';

import { Link } from 'react-router-dom';
import './Dashboard.css';

function Dashboard() {
  // const [name, setName] = useState('Raihan')
  // function handleChange() {
    
  // }
  return (
    <section id='dashboard'>
      
    <div>
    <h1>Dashboard</h1>
    <div>
      <Link to='/'></Link>
    </div>
  
    </div>
      {/* <Form
        showFormHeader={false}
        formSubmitButtonText={'Submit'}
        // handleSubmit={handleSubmit}
        encType={'multipart/form-data'}
      >
        <TextInput
          inputName={name}
          placeholder='Enter Name'
          setState={setName}
        />
        <SelectInput
          name={'random'}
          onChange={handleChange}
        >
          <SelectOption
           value={1}
           text={'1'}
          />
          <SelectOption
           value={2}
           text={'2'}
          />
        </SelectInput>
      </Form> */}
    </section>
  )
}

export default Dashboard