import { useState } from "react"
import axios from "axios"

import { DIVISIONS_API } from "../../../../Utilities/APIs"
import Form from "../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import LongTextInput from "../../../Partials/Layouts/Forms/FormInputs/LongTextInput/LongTextInput"
import { useEffect } from "react"


function UpdateDivision({

    targetID, employee, setShowUpdateForm, setShowModal, triggerFetch

}) {

    const [name, setName] = useState('')
    const [bngName, setBngName] = useState('')
    const [pathDrawn, setPathDrawn] = useState('')
    const [fillColor, setFillColor] = useState('')


    useEffect(() => {
        
        async function fetchAndSetSingleDivision() {
            const { data } = await axios.get(DIVISIONS_API + targetID)
            setName(data.name)
            setBngName(data.bngName)
            setPathDrawn(data.pathDrawn)
            setFillColor(data.fillColor)
        }
        fetchAndSetSingleDivision()
    }, [targetID])

    async function handleSubmit(e) {

        e.preventDefault()

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }
        const divisions = { name, bngName, pathDrawn, fillColor }

        const response = await axios.patch(DIVISIONS_API + targetID, divisions)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);

            triggerFetch()
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label={`name`}
                value={name}
                placeholder={`Enter Name`}
                setState={setName}
            />
            <ShortTextInput
                label={`Bangla Name`}
                value={bngName}
                placeholder={`Enter Bangla Name`}
                setState={setBngName}
            />
            <LongTextInput
                label={`Path Drawn`}
                value={pathDrawn}
                placeholder={`Enter Path Drawn`}
                setState={setPathDrawn}
            />
            <ShortTextInput
                label={`Fill Color`}
                value={fillColor}
                placeholder={`Enter Fill Color`}
                setState={setFillColor}
            />



            {/* <ImageInput fieldId='1' state={image} setState={setImage} allowCreateImage>Upload Card Image</ImageInput> */}

            <FormSubmitButton text='Update Division' />
        </Form>
    )
}

export default UpdateDivision