import { useState } from "react"
import axios from "axios"


import { PLACE_TO_VISITS_API } from "../../../../Utilities/APIs"
import Form from "../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import LinkInput from "../../../Partials/Layouts/Forms/FormInputs/LinkInput/LinkInput"


function CreatePlaceToVisit({

    district, employee, setShowCreateForm, setShowModal, triggerFetch

}) {

    const [name, setName] = useState('')
    const [mapLink, setMapLink] = useState('')
    const [wikiLink, setWikiLink] = useState('')





    async function handleSubmit(e) {

        e.preventDefault()

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }
        const placeToVisits = { district: district?._id, name, mapLink, wikiLink, }

        const response = await axios.post(PLACE_TO_VISITS_API, placeToVisits)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);

            triggerFetch()
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <h1 className="input_field_label">District</h1>
            <p className="short_title">{district?.name}</p>
            <ShortTextInput
                label={`Name`}
                value={name}
                placeholder={`Enter Name`}
                setState={setName}
            />
            <LinkInput
                label={`Map Link`}
                value={mapLink}
                placeholder={`Enter Map Link`}
                setState={setMapLink}
            />
            <LinkInput
                label={`Wiki Link`}
                value={wikiLink}
                placeholder={`Enter Wiki Link`}
                setState={setWikiLink}
            />




            {/* <ImageInput fieldId='1' state={image} setState={setImage} allowCreateImage>Upload Card Image</ImageInput> */}

            <FormSubmitButton text='Create Place To Visit' />
        </Form>
    )
}

export default CreatePlaceToVisit