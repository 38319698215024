import { useState, useEffect } from "react"
import axios from "axios"

import { AREAS_API } from "../../../../Utilities/APIs"


function ViewArea({

    //common props
    targetID, employee

}) {

    const [area, setArea] = useState(null)

    useEffect(() => {

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }

        async function fetchAndSetArea() {

            const { data } = await axios.get(AREAS_API   + targetID)
            setArea(data);
        }
        fetchAndSetArea()
    }, [targetID])

    return (

        <div className="crud_view_content">

            {area &&
                <>
                    <h1>Name</h1>
                    <p>{area.name}</p>
                    <h1>Bng Name</h1>
                    <p>{area.bngName}</p>
                    <h1>Path Drawn</h1>
                    <p>{area.district}</p>
                    
                    
                    
                  
                </>
            }
        </div>
    )
}

export default ViewArea