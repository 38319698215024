import { useState } from "react"
import axios from "axios"


import { FAMOUS_FOODS_API} from "../../../../Utilities/APIs"
import Form from "../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { useEffect } from "react"


function UpdateFamousFood({

   targetID, district, employee, setShowUpdateForm, setShowModal, triggerFetch, 

}) {

    const [name, setName] = useState('')
   
 
    useEffect(() => {
        
        async function fetchAndSetSingleDistrict() {
            const { data } = await axios.get(FAMOUS_FOODS_API + targetID)
            setName(data.name)
           
        }
        fetchAndSetSingleDistrict()
    }, [targetID])
 

    async function handleSubmit(e) {

        e.preventDefault()

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }
       const fomousFoods = {district: district?._id, name, }

        const response = await axios.patch(FAMOUS_FOODS_API + targetID , fomousFoods)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);

            triggerFetch()
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label={`name`}
                value={name}
                placeholder={`Enter Name`}
                setState={setName}
            />

            {/* <ImageInput fieldId='1' state={image} setState={setImage} allowCreateImage>Upload Card Image</ImageInput> */}

            <FormSubmitButton text='Update Famous Food' />
        </Form>
    )
}

export default UpdateFamousFood